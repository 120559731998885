import useAccountContext from "@/hooks/useAccountContext"
import { AvatarProps, Box } from "@chakra-ui/react"
import { useSession } from "next-auth/react"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import CustomTooltip from "../Buttons/CustomTooltip"
import UserAvar from "./UserAvatar"

const UserAvatarButton = ({
  tooltip,
  isMobile,
  ...props
}: { tooltip: boolean; isMobile?: boolean } & AvatarProps) => {
  const { status } = useSession()
  const { t } = useTranslation()
  const { user } = useAccountContext()

  let button = null

  if (status === "authenticated") {
    button = (
      <Box>
        <UserAvar user={user} {...props} />
      </Box>
    )
    if (tooltip) {
      button = (
        <CustomTooltip
          label={t("menu.account")}
          placement={isMobile ? "top" : "right"}
        >
          {button}
        </CustomTooltip>
      )
    }
    button = <Link href="/account">{button}</Link>
  }

  return button
}

export default UserAvatarButton
