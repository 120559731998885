import "../styles/globals.css"
import type { AppProps } from "next/app"

import { ChakraProvider } from "@chakra-ui/react"
import theme from "@/utils/theme"
import { appWithTranslation } from "next-i18next"
import { SessionProvider } from "next-auth/react"
import { Session } from "next-auth"
import { QueryClientProvider } from "react-query"
import { Layout } from "@/components/Layout"
import { AccountProvider } from "@/contexts/account-context"
import { customQueryClient } from "@/utils/queries"
import DefaultHead from "@/components/Layout/DefaultHead"
import { useEffect } from "react"
import client, { setInitialSession } from "@/api/client"

function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {

  if (session) {
    setInitialSession(session)
  }

  useEffect(() => {
    window.addDevice = (uuid: string, token: string) => {
      client.devices.postDevices({ data: { uuid, token } })
    }
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <SessionProvider session={session}>
        <QueryClientProvider client={customQueryClient}>
          <AccountProvider>
            <Layout>
              <DefaultHead />
              <Component {...pageProps} />
            </Layout>
          </AccountProvider>
        </QueryClientProvider>
      </SessionProvider>
    </ChakraProvider>
  )
}

export default appWithTranslation(App)
