import Head from "next/head"
import React from "react"
import { useTranslation } from "next-i18next"

const image = "https://amalibri.fr/og.png"

const DefaultHead = () => {
  const { t, ready } = useTranslation()

  const title = ready ? t("seo.title") : "Amalibri"
  const description = ready
    ? t("seo.decription")
    : "Partagez vos lectures favorites avec vos amis et vos proches"

  return (
    <Head>
      <title>{title}</title>
      <link rel="shortcut icon" href="/favicon.png" />
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta itemProp="image" content={image} />
      <meta property="og:logo" content={image}></meta>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Head>
  )
}

export default DefaultHead
