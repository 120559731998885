/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
  data?: object | [] | null
  error: {
    status?: number
    name?: string
    message?: string
    details?: object
  }
}

export interface ActivityListResponseDataItem {
  id?: number
  attributes?: {
    type?: "GROUP_RECOMMENDATION" | "GROUP_REVIEW" | "GROUP_SUMMARY" | "GROUP_MESSAGE" | "GROUP_REVIEW_MESSAGE"
    author?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ActivityListResponseDataItemLocalized {
  id?: number
  attributes?: {
    type?: "GROUP_RECOMMENDATION" | "GROUP_REVIEW" | "GROUP_SUMMARY" | "GROUP_MESSAGE" | "GROUP_REVIEW_MESSAGE"
    author?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ActivityListResponse {
  data?: ActivityListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface ActivityResponseDataObject {
  id?: number
  attributes?: {
    type?: "GROUP_RECOMMENDATION" | "GROUP_REVIEW" | "GROUP_SUMMARY" | "GROUP_MESSAGE" | "GROUP_REVIEW_MESSAGE"
    author?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ActivityResponseDataObjectLocalized {
  id?: number
  attributes?: {
    type?: "GROUP_RECOMMENDATION" | "GROUP_REVIEW" | "GROUP_SUMMARY" | "GROUP_MESSAGE" | "GROUP_REVIEW_MESSAGE"
    author?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ActivityResponse {
  data?: ActivityResponseDataObject
  meta?: object
}

export interface ArticleRequest {
  data: {
    title: string
    content: string
    /** @example "string or id" */
    cover: number | string
    tags?: (number | string)[]
    subTitle?: string
  }
}

export interface ArticleListResponseDataItem {
  id?: number
  attributes?: {
    title?: string
    content?: string
    cover?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: {
          type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
          label?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          localizations?: {
            data?: any[]
          }
          locale?: string
        }
      }[]
    }
    subTitle?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ArticleListResponseDataItemLocalized {
  id?: number
  attributes?: {
    title?: string
    content?: string
    cover?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: {
          type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
          label?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          localizations?: {
            data?: any[]
          }
          locale?: string
        }
      }[]
    }
    subTitle?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ArticleListResponse {
  data?: ArticleListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface ArticleResponseDataObject {
  id?: number
  attributes?: {
    title?: string
    content?: string
    cover?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: {
          type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
          label?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          localizations?: {
            data?: any[]
          }
          locale?: string
        }
      }[]
    }
    subTitle?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ArticleResponseDataObjectLocalized {
  id?: number
  attributes?: {
    title?: string
    content?: string
    cover?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: {
          type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
          label?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          localizations?: {
            data?: any[]
          }
          locale?: string
        }
      }[]
    }
    subTitle?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ArticleResponse {
  data?: ArticleResponseDataObject
  meta?: object
}

export interface BookRequest {
  data: {
    title: string
    editor?: string
    format?: string
    isbn?: string
    pages?: number
    year?: number
    author?: string
    custom_isbn?: string
  }
}

export interface BookListResponseDataItem {
  id?: number
  attributes?: {
    title?: string
    editor?: string
    format?: string
    isbn?: string
    pages?: number
    year?: number
    author?: string
    custom_isbn?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface BookListResponseDataItemLocalized {
  id?: number
  attributes?: {
    title?: string
    editor?: string
    format?: string
    isbn?: string
    pages?: number
    year?: number
    author?: string
    custom_isbn?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface BookListResponse {
  data?: BookListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface BookResponseDataObject {
  id?: number
  attributes?: {
    title?: string
    editor?: string
    format?: string
    isbn?: string
    pages?: number
    year?: number
    author?: string
    custom_isbn?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface BookResponseDataObjectLocalized {
  id?: number
  attributes?: {
    title?: string
    editor?: string
    format?: string
    isbn?: string
    pages?: number
    year?: number
    author?: string
    custom_isbn?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface BookResponse {
  data?: BookResponseDataObject
  meta?: object
}

export interface CguRequest {
  data: {
    content?: string
    title?: string
  }
}

export interface CguListResponseDataItem {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CguListResponseDataItemLocalized {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CguListResponse {
  data?: CguListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface CguResponseDataObject {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CguResponseDataObjectLocalized {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CguResponse {
  data?: CguResponseDataObject
  meta?: object
}

export interface CommentRequest {
  data: {
    type?: "summary" | "discussion" | "question"
    content: string
    /** @example "string or id" */
    group?: number | string
    /** @example "string or id" */
    review?: number | string
    /** @example "string or id" */
    author?: number | string
    has_spoil?: boolean
  }
}

export interface CommentListResponseDataItem {
  id?: number
  attributes?: {
    type?: "summary" | "discussion" | "question"
    content?: string
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    author?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    has_spoil?: boolean
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CommentListResponseDataItemLocalized {
  id?: number
  attributes?: {
    type?: "summary" | "discussion" | "question"
    content?: string
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    author?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    has_spoil?: boolean
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CommentListResponse {
  data?: CommentListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface CommentResponseDataObject {
  id?: number
  attributes?: {
    type?: "summary" | "discussion" | "question"
    content?: string
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    author?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    has_spoil?: boolean
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CommentResponseDataObjectLocalized {
  id?: number
  attributes?: {
    type?: "summary" | "discussion" | "question"
    content?: string
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    author?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    has_spoil?: boolean
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface CommentResponse {
  data?: CommentResponseDataObject
  meta?: object
}

export interface DeviceRequest {
  data: {
    token?: string
    uuid: string
    /** @example "string or id" */
    user?: number | string
  }
}

export interface DeviceListResponseDataItem {
  id?: number
  attributes?: {
    token?: string
    uuid?: string
    user?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface DeviceListResponseDataItemLocalized {
  id?: number
  attributes?: {
    token?: string
    uuid?: string
    user?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface DeviceListResponse {
  data?: DeviceListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface DeviceResponseDataObject {
  id?: number
  attributes?: {
    token?: string
    uuid?: string
    user?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface DeviceResponseDataObjectLocalized {
  id?: number
  attributes?: {
    token?: string
    uuid?: string
    user?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface DeviceResponse {
  data?: DeviceResponseDataObject
  meta?: object
}

export interface GradeRequest {
  data: {
    users_rating?: any
    /** @format float */
    average_rate?: number
    /** @example "string or id" */
    group?: number | string
    /** @example "string or id" */
    review?: number | string
    slug?: string
  }
}

export interface GradeListResponseDataItem {
  id?: number
  attributes?: {
    users_rating?: any
    /** @format float */
    average_rate?: number
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GradeListResponseDataItemLocalized {
  id?: number
  attributes?: {
    users_rating?: any
    /** @format float */
    average_rate?: number
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GradeListResponse {
  data?: GradeListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface GradeResponseDataObject {
  id?: number
  attributes?: {
    users_rating?: any
    /** @format float */
    average_rate?: number
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GradeResponseDataObjectLocalized {
  id?: number
  attributes?: {
    users_rating?: any
    /** @format float */
    average_rate?: number
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GradeResponse {
  data?: GradeResponseDataObject
  meta?: object
}

export interface GroupRequest {
  data: {
    name: string
    description?: string
    memberships?: (number | string)[]
    reviews?: (number | string)[]
    activities?: (number | string)[]
    type: "half-private" | "private"
  }
}

export interface GroupListResponseDataItem {
  id?: number
  attributes?: {
    name?: string
    description?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    reviews?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    type?: "half-private" | "private"
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GroupListResponseDataItemLocalized {
  id?: number
  attributes?: {
    name?: string
    description?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    reviews?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    type?: "half-private" | "private"
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GroupListResponse {
  data?: GroupListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface GroupResponseDataObject {
  id?: number
  attributes?: {
    name?: string
    description?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    reviews?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    type?: "half-private" | "private"
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GroupResponseDataObjectLocalized {
  id?: number
  attributes?: {
    name?: string
    description?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    reviews?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    type?: "half-private" | "private"
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface GroupResponse {
  data?: GroupResponseDataObject
  meta?: object
}

export interface InvitationRequest {
  data: {
    /** @example "string or id" */
    membership?: number | string
    /** @format date-time */
    expired_at?: string
    /** @format date-time */
    validated_at?: string
    uid?: string
  }
}

export interface InvitationListResponseDataItem {
  id?: number
  attributes?: {
    membership?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    expired_at?: string
    /** @format date-time */
    validated_at?: string
    uid?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface InvitationListResponseDataItemLocalized {
  id?: number
  attributes?: {
    membership?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    expired_at?: string
    /** @format date-time */
    validated_at?: string
    uid?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface InvitationListResponse {
  data?: InvitationListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface InvitationResponseDataObject {
  id?: number
  attributes?: {
    membership?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    expired_at?: string
    /** @format date-time */
    validated_at?: string
    uid?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface InvitationResponseDataObjectLocalized {
  id?: number
  attributes?: {
    membership?: {
      data?: {
        id?: number
        attributes?: {
          membership_role?: "admin" | "user"
          group?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                reviews?: {
                  data?: {
                    id?: number
                    attributes?: {
                      book?: {
                        data?: {
                          id?: number
                          attributes?: {
                            title?: string
                            editor?: string
                            format?: string
                            isbn?: string
                            pages?: number
                            year?: number
                            author?: string
                            custom_isbn?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      summary?: string
                      has_spoil?: boolean
                      grade?: number
                      groups?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      illustration?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      owner?: {
                        data?: {
                          id?: number
                          attributes?: {
                            username?: string
                            /** @format email */
                            email?: string
                            provider?: string
                            resetPasswordToken?: string
                            confirmationToken?: string
                            confirmed?: boolean
                            blocked?: boolean
                            role?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  type?: string
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            cgu_consent?: boolean
                            newsletter_consent?: boolean
                            color?: string
                            first_name?: string
                            last_name?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            picture?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            notifications_consent?: boolean
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            section_visits?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  last_seen?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            mobile_notifications_consent?: boolean
                            devices?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  token?: string
                                  uuid?: string
                                  user?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      tags?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                            label?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            localizations?: {
                              data?: any[]
                            }
                            locale?: string
                          }
                        }[]
                      }
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      groupGrades?: {
                        data?: {
                          id?: number
                          attributes?: {
                            users_rating?: any
                            /** @format float */
                            average_rate?: number
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            slug?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                type?: "half-private" | "private"
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          users_permissions_user?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          invited_email?: string
          invitation?: {
            data?: {
              id?: number
              attributes?: {
                membership?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                expired_at?: string
                /** @format date-time */
                validated_at?: string
                uid?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    expired_at?: string
    /** @format date-time */
    validated_at?: string
    uid?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface InvitationResponse {
  data?: InvitationResponseDataObject
  meta?: object
}

export interface LandingPageRequest {
  data: {
    carousel?: PagePhotoComponent[]
    presentation_1?: PagePresentationComponent
    presentation_2?: PagePresentationComponent
    presentation_3?: PagePresentationComponent
  }
}

export interface LandingPageListResponseDataItem {
  id?: number
  attributes?: {
    carousel?: PagePhotoComponent[]
    presentation_1?: PagePresentationComponent
    presentation_2?: PagePresentationComponent
    presentation_3?: PagePresentationComponent
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LandingPageListResponseDataItemLocalized {
  id?: number
  attributes?: {
    carousel?: PagePhotoComponent[]
    presentation_1?: PagePresentationComponent
    presentation_2?: PagePresentationComponent
    presentation_3?: PagePresentationComponent
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LandingPageListResponse {
  data?: LandingPageListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface LandingPageResponseDataObject {
  id?: number
  attributes?: {
    carousel?: PagePhotoComponent[]
    presentation_1?: PagePresentationComponent
    presentation_2?: PagePresentationComponent
    presentation_3?: PagePresentationComponent
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LandingPageResponseDataObjectLocalized {
  id?: number
  attributes?: {
    carousel?: PagePhotoComponent[]
    presentation_1?: PagePresentationComponent
    presentation_2?: PagePresentationComponent
    presentation_3?: PagePresentationComponent
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LandingPageResponse {
  data?: LandingPageResponseDataObject
  meta?: object
}

export interface PagePhotoComponent {
  id?: number
  label?: string
  image?: {
    data?: {
      id?: number
      attributes?: {
        name?: string
        alternativeText?: string
        caption?: string
        width?: number
        height?: number
        formats?: any
        hash?: string
        ext?: string
        mime?: string
        /** @format float */
        size?: number
        url?: string
        previewUrl?: string
        provider?: string
        provider_metadata?: any
        related?: {
          data?: {
            id?: number
            attributes?: object
          }[]
        }
        folder?: {
          data?: {
            id?: number
            attributes?: {
              name?: string
              pathId?: number
              parent?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
              children?: {
                data?: {
                  id?: number
                  attributes?: object
                }[]
              }
              files?: {
                data?: {
                  id?: number
                  attributes?: {
                    name?: string
                    alternativeText?: string
                    caption?: string
                    width?: number
                    height?: number
                    formats?: any
                    hash?: string
                    ext?: string
                    mime?: string
                    /** @format float */
                    size?: number
                    url?: string
                    previewUrl?: string
                    provider?: string
                    provider_metadata?: any
                    related?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }[]
                    }
                    folder?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }
                    }
                    folderPath?: string
                    /** @format date-time */
                    createdAt?: string
                    /** @format date-time */
                    updatedAt?: string
                    createdBy?: {
                      data?: {
                        id?: number
                        attributes?: {
                          firstname?: string
                          lastname?: string
                          username?: string
                          /** @format email */
                          email?: string
                          resetPasswordToken?: string
                          registrationToken?: string
                          isActive?: boolean
                          roles?: {
                            data?: {
                              id?: number
                              attributes?: {
                                name?: string
                                code?: string
                                description?: string
                                users?: {
                                  data?: {
                                    id?: number
                                    attributes?: object
                                  }[]
                                }
                                permissions?: {
                                  data?: {
                                    id?: number
                                    attributes?: {
                                      action?: string
                                      subject?: string
                                      properties?: any
                                      conditions?: any
                                      role?: {
                                        data?: {
                                          id?: number
                                          attributes?: object
                                        }
                                      }
                                      /** @format date-time */
                                      createdAt?: string
                                      /** @format date-time */
                                      updatedAt?: string
                                      createdBy?: {
                                        data?: {
                                          id?: number
                                          attributes?: object
                                        }
                                      }
                                      updatedBy?: {
                                        data?: {
                                          id?: number
                                          attributes?: object
                                        }
                                      }
                                    }
                                  }[]
                                }
                                /** @format date-time */
                                createdAt?: string
                                /** @format date-time */
                                updatedAt?: string
                                createdBy?: {
                                  data?: {
                                    id?: number
                                    attributes?: object
                                  }
                                }
                                updatedBy?: {
                                  data?: {
                                    id?: number
                                    attributes?: object
                                  }
                                }
                              }
                            }[]
                          }
                          blocked?: boolean
                          preferedLanguage?: string
                          /** @format date-time */
                          createdAt?: string
                          /** @format date-time */
                          updatedAt?: string
                          createdBy?: {
                            data?: {
                              id?: number
                              attributes?: object
                            }
                          }
                          updatedBy?: {
                            data?: {
                              id?: number
                              attributes?: object
                            }
                          }
                        }
                      }
                    }
                    updatedBy?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }
                    }
                  }
                }[]
              }
              path?: string
              /** @format date-time */
              createdAt?: string
              /** @format date-time */
              updatedAt?: string
              createdBy?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
              updatedBy?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
            }
          }
        }
        folderPath?: string
        /** @format date-time */
        createdAt?: string
        /** @format date-time */
        updatedAt?: string
        createdBy?: {
          data?: {
            id?: number
            attributes?: object
          }
        }
        updatedBy?: {
          data?: {
            id?: number
            attributes?: object
          }
        }
      }
    }
  }
}

export interface PagePresentationComponent {
  id?: number
  description?: string
  image?: {
    data?: {
      id?: number
      attributes?: {
        name?: string
        alternativeText?: string
        caption?: string
        width?: number
        height?: number
        formats?: any
        hash?: string
        ext?: string
        mime?: string
        /** @format float */
        size?: number
        url?: string
        previewUrl?: string
        provider?: string
        provider_metadata?: any
        related?: {
          data?: {
            id?: number
            attributes?: object
          }[]
        }
        folder?: {
          data?: {
            id?: number
            attributes?: {
              name?: string
              pathId?: number
              parent?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
              children?: {
                data?: {
                  id?: number
                  attributes?: object
                }[]
              }
              files?: {
                data?: {
                  id?: number
                  attributes?: {
                    name?: string
                    alternativeText?: string
                    caption?: string
                    width?: number
                    height?: number
                    formats?: any
                    hash?: string
                    ext?: string
                    mime?: string
                    /** @format float */
                    size?: number
                    url?: string
                    previewUrl?: string
                    provider?: string
                    provider_metadata?: any
                    related?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }[]
                    }
                    folder?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }
                    }
                    folderPath?: string
                    /** @format date-time */
                    createdAt?: string
                    /** @format date-time */
                    updatedAt?: string
                    createdBy?: {
                      data?: {
                        id?: number
                        attributes?: {
                          firstname?: string
                          lastname?: string
                          username?: string
                          /** @format email */
                          email?: string
                          resetPasswordToken?: string
                          registrationToken?: string
                          isActive?: boolean
                          roles?: {
                            data?: {
                              id?: number
                              attributes?: {
                                name?: string
                                code?: string
                                description?: string
                                users?: {
                                  data?: {
                                    id?: number
                                    attributes?: object
                                  }[]
                                }
                                permissions?: {
                                  data?: {
                                    id?: number
                                    attributes?: {
                                      action?: string
                                      subject?: string
                                      properties?: any
                                      conditions?: any
                                      role?: {
                                        data?: {
                                          id?: number
                                          attributes?: object
                                        }
                                      }
                                      /** @format date-time */
                                      createdAt?: string
                                      /** @format date-time */
                                      updatedAt?: string
                                      createdBy?: {
                                        data?: {
                                          id?: number
                                          attributes?: object
                                        }
                                      }
                                      updatedBy?: {
                                        data?: {
                                          id?: number
                                          attributes?: object
                                        }
                                      }
                                    }
                                  }[]
                                }
                                /** @format date-time */
                                createdAt?: string
                                /** @format date-time */
                                updatedAt?: string
                                createdBy?: {
                                  data?: {
                                    id?: number
                                    attributes?: object
                                  }
                                }
                                updatedBy?: {
                                  data?: {
                                    id?: number
                                    attributes?: object
                                  }
                                }
                              }
                            }[]
                          }
                          blocked?: boolean
                          preferedLanguage?: string
                          /** @format date-time */
                          createdAt?: string
                          /** @format date-time */
                          updatedAt?: string
                          createdBy?: {
                            data?: {
                              id?: number
                              attributes?: object
                            }
                          }
                          updatedBy?: {
                            data?: {
                              id?: number
                              attributes?: object
                            }
                          }
                        }
                      }
                    }
                    updatedBy?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }
                    }
                  }
                }[]
              }
              path?: string
              /** @format date-time */
              createdAt?: string
              /** @format date-time */
              updatedAt?: string
              createdBy?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
              updatedBy?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
            }
          }
        }
        folderPath?: string
        /** @format date-time */
        createdAt?: string
        /** @format date-time */
        updatedAt?: string
        createdBy?: {
          data?: {
            id?: number
            attributes?: object
          }
        }
        updatedBy?: {
          data?: {
            id?: number
            attributes?: object
          }
        }
      }
    }
  }
}

export interface LegalNoticeRequest {
  data: {
    title: string
    content: string
  }
}

export interface LegalNoticeListResponseDataItem {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LegalNoticeListResponseDataItemLocalized {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LegalNoticeListResponse {
  data?: LegalNoticeListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface LegalNoticeResponseDataObject {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LegalNoticeResponseDataObjectLocalized {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface LegalNoticeResponse {
  data?: LegalNoticeResponseDataObject
  meta?: object
}

export interface MembershipRequest {
  data: {
    membership_role?: "admin" | "user"
    /** @example "string or id" */
    group?: number | string
    /** @example "string or id" */
    users_permissions_user?: number | string
    invited_email?: string
    /** @example "string or id" */
    invitation?: number | string
  }
}

export interface MembershipListResponseDataItem {
  id?: number
  attributes?: {
    membership_role?: "admin" | "user"
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    users_permissions_user?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    invited_email?: string
    invitation?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface MembershipListResponseDataItemLocalized {
  id?: number
  attributes?: {
    membership_role?: "admin" | "user"
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    users_permissions_user?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    invited_email?: string
    invitation?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface MembershipListResponse {
  data?: MembershipListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface MembershipResponseDataObject {
  id?: number
  attributes?: {
    membership_role?: "admin" | "user"
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    users_permissions_user?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    invited_email?: string
    invitation?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface MembershipResponseDataObjectLocalized {
  id?: number
  attributes?: {
    membership_role?: "admin" | "user"
    group?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    users_permissions_user?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    invited_email?: string
    invitation?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface MembershipResponse {
  data?: MembershipResponseDataObject
  meta?: object
}

export interface PartnershipRequest {
  data: {
    /** @example "string or id" */
    groupPartnership?: number | string
    /** @example "string or id" */
    libraryPartnership?: number | string
    /** @example "string or id" */
    reviewsPartnership?: number | string
  }
}

export interface PartnershipListResponseDataItem {
  id?: number
  attributes?: {
    groupPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    libraryPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    reviewsPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PartnershipListResponseDataItemLocalized {
  id?: number
  attributes?: {
    groupPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    libraryPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    reviewsPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PartnershipListResponse {
  data?: PartnershipListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface PartnershipResponseDataObject {
  id?: number
  attributes?: {
    groupPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    libraryPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    reviewsPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PartnershipResponseDataObjectLocalized {
  id?: number
  attributes?: {
    groupPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    libraryPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    reviewsPartnership?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PartnershipResponse {
  data?: PartnershipResponseDataObject
  meta?: object
}

export interface PrivacyPolicyRequest {
  data: {
    content?: string
    title?: string
  }
}

export interface PrivacyPolicyListResponseDataItem {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PrivacyPolicyListResponseDataItemLocalized {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PrivacyPolicyListResponse {
  data?: PrivacyPolicyListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface PrivacyPolicyResponseDataObject {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PrivacyPolicyResponseDataObjectLocalized {
  id?: number
  attributes?: {
    content?: string
    title?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface PrivacyPolicyResponse {
  data?: PrivacyPolicyResponseDataObject
  meta?: object
}

export interface RecommendationRequest {
  data: {
    /** @example "string or id" */
    emitter?: number | string
    /** @example "string or id" */
    receiver?: number | string
    /** @example "string or id" */
    review?: number | string
    slug?: string
    /** @example "string or id" */
    group?: number | string
  }
}

export interface RecommendationListResponseDataItem {
  id?: number
  attributes?: {
    emitter?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    receiver?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface RecommendationListResponseDataItemLocalized {
  id?: number
  attributes?: {
    emitter?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    receiver?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface RecommendationListResponse {
  data?: RecommendationListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface RecommendationResponseDataObject {
  id?: number
  attributes?: {
    emitter?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    receiver?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface RecommendationResponseDataObjectLocalized {
  id?: number
  attributes?: {
    emitter?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    receiver?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    review?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    slug?: string
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface RecommendationResponse {
  data?: RecommendationResponseDataObject
  meta?: object
}

export interface ReviewRequest {
  data: {
    /** @example "string or id" */
    book?: number | string
    summary?: string
    has_spoil?: boolean
    grade: number
    groups?: (number | string)[]
    /** @example "string or id" */
    illustration?: number | string
    /** @example "string or id" */
    owner?: number | string
    tags?: (number | string)[]
    comments?: (number | string)[]
    groupGrades?: (number | string)[]
  }
}

export interface ReviewListResponseDataItem {
  id?: number
  attributes?: {
    book?: {
      data?: {
        id?: number
        attributes?: {
          title?: string
          editor?: string
          format?: string
          isbn?: string
          pages?: number
          year?: number
          author?: string
          custom_isbn?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    summary?: string
    has_spoil?: boolean
    grade?: number
    groups?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    illustration?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    owner?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    groupGrades?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ReviewListResponseDataItemLocalized {
  id?: number
  attributes?: {
    book?: {
      data?: {
        id?: number
        attributes?: {
          title?: string
          editor?: string
          format?: string
          isbn?: string
          pages?: number
          year?: number
          author?: string
          custom_isbn?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    summary?: string
    has_spoil?: boolean
    grade?: number
    groups?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    illustration?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    owner?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    groupGrades?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ReviewListResponse {
  data?: ReviewListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface ReviewResponseDataObject {
  id?: number
  attributes?: {
    book?: {
      data?: {
        id?: number
        attributes?: {
          title?: string
          editor?: string
          format?: string
          isbn?: string
          pages?: number
          year?: number
          author?: string
          custom_isbn?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    summary?: string
    has_spoil?: boolean
    grade?: number
    groups?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    illustration?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    owner?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    groupGrades?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ReviewResponseDataObjectLocalized {
  id?: number
  attributes?: {
    book?: {
      data?: {
        id?: number
        attributes?: {
          title?: string
          editor?: string
          format?: string
          isbn?: string
          pages?: number
          year?: number
          author?: string
          custom_isbn?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: {
                firstname?: string
                lastname?: string
                username?: string
                /** @format email */
                email?: string
                resetPasswordToken?: string
                registrationToken?: string
                isActive?: boolean
                roles?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      code?: string
                      description?: string
                      users?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      permissions?: {
                        data?: {
                          id?: number
                          attributes?: {
                            action?: string
                            subject?: string
                            properties?: any
                            conditions?: any
                            role?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                blocked?: boolean
                preferedLanguage?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    summary?: string
    has_spoil?: boolean
    grade?: number
    groups?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            type?: string
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?: "summary" | "discussion" | "question"
                            content?: string
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            has_spoil?: boolean
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          reviews?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          type?: "half-private" | "private"
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    illustration?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    owner?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    tags?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    groupGrades?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface ReviewResponse {
  data?: ReviewResponseDataObject
  meta?: object
}

export interface SectionVisitRequest {
  data: {
    /** @example "string or id" */
    user?: number | string
    type: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
    /** @example "string or id" */
    group?: number | string
    /** @format date-time */
    last_seen?: string
  }
}

export interface SectionVisitResponseDataObject {
  id?: number
  attributes?: {
    user?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    last_seen?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface SectionVisitResponseDataObjectLocalized {
  id?: number
  attributes?: {
    user?: {
      data?: {
        id?: number
        attributes?: {
          username?: string
          /** @format email */
          email?: string
          provider?: string
          resetPasswordToken?: string
          confirmationToken?: string
          confirmed?: boolean
          blocked?: boolean
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          cgu_consent?: boolean
          newsletter_consent?: boolean
          color?: string
          first_name?: string
          last_name?: string
          memberships?: {
            data?: {
              id?: number
              attributes?: {
                membership_role?: "admin" | "user"
                group?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      description?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      reviews?: {
                        data?: {
                          id?: number
                          attributes?: {
                            book?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  title?: string
                                  editor?: string
                                  format?: string
                                  isbn?: string
                                  pages?: number
                                  year?: number
                                  author?: string
                                  custom_isbn?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            summary?: string
                            has_spoil?: boolean
                            grade?: number
                            groups?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            illustration?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        pathId?: number
                                        parent?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        children?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        files?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        path?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            owner?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            tags?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                  label?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  localizations?: {
                                    data?: any[]
                                  }
                                  locale?: string
                                }
                              }[]
                            }
                            comments?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?: "summary" | "discussion" | "question"
                                  content?: string
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  has_spoil?: boolean
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            groupGrades?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  users_rating?: any
                                  /** @format float */
                                  average_rate?: number
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  slug?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: {
                            type?:
                              | "GROUP_RECOMMENDATION"
                              | "GROUP_REVIEW"
                              | "GROUP_SUMMARY"
                              | "GROUP_MESSAGE"
                              | "GROUP_REVIEW_MESSAGE"
                            author?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            review?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      type?: "half-private" | "private"
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                users_permissions_user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                invited_email?: string
                invitation?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      expired_at?: string
                      /** @format date-time */
                      validated_at?: string
                      uid?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          picture?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      pathId?: number
                      parent?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      children?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      files?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  firstname?: string
                                  lastname?: string
                                  username?: string
                                  /** @format email */
                                  email?: string
                                  resetPasswordToken?: string
                                  registrationToken?: string
                                  isActive?: boolean
                                  roles?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        code?: string
                                        description?: string
                                        users?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        permissions?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              action?: string
                                              subject?: string
                                              properties?: any
                                              conditions?: any
                                              role?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  blocked?: boolean
                                  preferedLanguage?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      path?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          notifications_consent?: boolean
          comments?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          activities?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          section_visits?: {
            data?: {
              id?: number
              attributes?: {
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                group?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                last_seen?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          mobile_notifications_consent?: boolean
          devices?: {
            data?: {
              id?: number
              attributes?: {
                token?: string
                uuid?: string
                user?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
    group?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    /** @format date-time */
    last_seen?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface SectionVisitResponse {
  data?: SectionVisitResponseDataObject
  meta?: object
}

export interface TagLocalizationRequest {
  type: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
  label: string
  locale: string
}

export interface TagRequest {
  data: {
    type: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
    label: string
    locale?: string
  }
}

export interface TagLocalizationResponse {
  id?: number
  type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
  label?: string
  /** @format date-time */
  createdAt?: string
  /** @format date-time */
  updatedAt?: string
  createdBy?: {
    data?: {
      id?: number
      attributes?: {
        firstname?: string
        lastname?: string
        username?: string
        /** @format email */
        email?: string
        resetPasswordToken?: string
        registrationToken?: string
        isActive?: boolean
        roles?: {
          data?: {
            id?: number
            attributes?: {
              name?: string
              code?: string
              description?: string
              users?: {
                data?: {
                  id?: number
                  attributes?: object
                }[]
              }
              permissions?: {
                data?: {
                  id?: number
                  attributes?: {
                    action?: string
                    subject?: string
                    properties?: any
                    conditions?: any
                    role?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }
                    }
                    /** @format date-time */
                    createdAt?: string
                    /** @format date-time */
                    updatedAt?: string
                    createdBy?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }
                    }
                    updatedBy?: {
                      data?: {
                        id?: number
                        attributes?: object
                      }
                    }
                  }
                }[]
              }
              /** @format date-time */
              createdAt?: string
              /** @format date-time */
              updatedAt?: string
              createdBy?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
              updatedBy?: {
                data?: {
                  id?: number
                  attributes?: object
                }
              }
            }
          }[]
        }
        blocked?: boolean
        preferedLanguage?: string
        /** @format date-time */
        createdAt?: string
        /** @format date-time */
        updatedAt?: string
        createdBy?: {
          data?: {
            id?: number
            attributes?: object
          }
        }
        updatedBy?: {
          data?: {
            id?: number
            attributes?: object
          }
        }
      }
    }
  }
  updatedBy?: {
    data?: {
      id?: number
      attributes?: object
    }
  }
  localizations?: {
    data?: any[]
  }
  locale?: string
}

export interface TagListResponseDataItem {
  id?: number
  attributes?: {
    type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
    label?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    localizations?: {
      data?: TagListResponseDataItemLocalized[]
    }
    locale?: string
  }
}

export interface TagListResponseDataItemLocalized {
  id?: number
  attributes?: {
    type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
    label?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    localizations?: {
      data?: any[]
    }
    locale?: string
  }
}

export interface TagListResponse {
  data?: TagListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface TagResponseDataObject {
  id?: number
  attributes?: {
    type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
    label?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    localizations?: {
      data?: TagResponseDataObjectLocalized[]
    }
    locale?: string
  }
}

export interface TagResponseDataObjectLocalized {
  id?: number
  attributes?: {
    type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
    label?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    localizations?: {
      data?: any[]
    }
    locale?: string
  }
}

export interface TagResponse {
  data?: TagResponseDataObject
  meta?: object
}

export interface TutorialNoticeRequest {
  data: {
    title: string
    content: string
  }
}

export interface TutorialNoticeListResponseDataItem {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface TutorialNoticeListResponseDataItemLocalized {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface TutorialNoticeListResponse {
  data?: TutorialNoticeListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface TutorialNoticeResponseDataObject {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface TutorialNoticeResponseDataObjectLocalized {
  id?: number
  attributes?: {
    title?: string
    content?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    /** @format date-time */
    publishedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: {
          firstname?: string
          lastname?: string
          username?: string
          /** @format email */
          email?: string
          resetPasswordToken?: string
          registrationToken?: string
          isActive?: boolean
          roles?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                code?: string
                description?: string
                users?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: {
                      action?: string
                      subject?: string
                      properties?: any
                      conditions?: any
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          blocked?: boolean
          preferedLanguage?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface TutorialNoticeResponse {
  data?: TutorialNoticeResponseDataObject
  meta?: object
}

export interface UploadFileRequest {
  data: {
    name: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash: string
    ext?: string
    mime: string
    /** @format float */
    size: number
    url: string
    previewUrl?: string
    provider: string
    provider_metadata?: any
    related?: (number | string)[]
    /** @example "string or id" */
    folder?: number | string
    folderPath: string
  }
}

export interface UploadFileListResponseDataItem {
  id?: number
  attributes?: {
    name?: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash?: string
    ext?: string
    mime?: string
    /** @format float */
    size?: number
    url?: string
    previewUrl?: string
    provider?: string
    provider_metadata?: any
    related?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    folder?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    folderPath?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFileListResponseDataItemLocalized {
  id?: number
  attributes?: {
    name?: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash?: string
    ext?: string
    mime?: string
    /** @format float */
    size?: number
    url?: string
    previewUrl?: string
    provider?: string
    provider_metadata?: any
    related?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    folder?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    folderPath?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFileListResponse {
  data?: UploadFileListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface UploadFileResponseDataObject {
  id?: number
  attributes?: {
    name?: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash?: string
    ext?: string
    mime?: string
    /** @format float */
    size?: number
    url?: string
    previewUrl?: string
    provider?: string
    provider_metadata?: any
    related?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    folder?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    folderPath?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFileResponseDataObjectLocalized {
  id?: number
  attributes?: {
    name?: string
    alternativeText?: string
    caption?: string
    width?: number
    height?: number
    formats?: any
    hash?: string
    ext?: string
    mime?: string
    /** @format float */
    size?: number
    url?: string
    previewUrl?: string
    provider?: string
    provider_metadata?: any
    related?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    folder?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    folderPath?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFileResponse {
  data?: UploadFileResponseDataObject
  meta?: object
}

export interface UploadFolderRequest {
  data: {
    name: string
    pathId: number
    /** @example "string or id" */
    parent?: number | string
    children?: (number | string)[]
    files?: (number | string)[]
    path: string
  }
}

export interface UploadFolderListResponseDataItem {
  id?: number
  attributes?: {
    name?: string
    pathId?: number
    parent?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    children?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    files?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    path?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFolderListResponseDataItemLocalized {
  id?: number
  attributes?: {
    name?: string
    pathId?: number
    parent?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    children?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    files?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    path?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFolderListResponse {
  data?: UploadFolderListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface UploadFolderResponseDataObject {
  id?: number
  attributes?: {
    name?: string
    pathId?: number
    parent?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    children?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    files?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    path?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFolderResponseDataObjectLocalized {
  id?: number
  attributes?: {
    name?: string
    pathId?: number
    parent?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          pathId?: number
          parent?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          children?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          files?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                alternativeText?: string
                caption?: string
                width?: number
                height?: number
                formats?: any
                hash?: string
                ext?: string
                mime?: string
                /** @format float */
                size?: number
                url?: string
                previewUrl?: string
                provider?: string
                provider_metadata?: any
                related?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                folder?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                folderPath?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          path?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    children?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    files?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    path?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UploadFolderResponse {
  data?: UploadFolderResponseDataObject
  meta?: object
}

export interface UsersPermissionsPermissionRequest {
  data: {
    action: string
    /** @example "string or id" */
    role?: number | string
  }
}

export interface UsersPermissionsPermissionListResponseDataItem {
  id?: number
  attributes?: {
    action?: string
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsPermissionListResponseDataItemLocalized {
  id?: number
  attributes?: {
    action?: string
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsPermissionListResponse {
  data?: UsersPermissionsPermissionListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface UsersPermissionsPermissionResponseDataObject {
  id?: number
  attributes?: {
    action?: string
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsPermissionResponseDataObjectLocalized {
  id?: number
  attributes?: {
    action?: string
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsPermissionResponse {
  data?: UsersPermissionsPermissionResponseDataObject
  meta?: object
}

export interface UsersPermissionsRoleRequest {
  data: {
    name: string
    description?: string
    type?: string
    permissions?: (number | string)[]
    users?: (number | string)[]
  }
}

export interface UsersPermissionsRoleListResponseDataItem {
  id?: number
  attributes?: {
    name?: string
    description?: string
    type?: string
    permissions?: {
      data?: {
        id?: number
        attributes?: {
          action?: string
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership_role?: "admin" | "user"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  memberships?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  reviews?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        book?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              title?: string
                                              editor?: string
                                              format?: string
                                              isbn?: string
                                              pages?: number
                                              year?: number
                                              author?: string
                                              custom_isbn?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        summary?: string
                                        has_spoil?: boolean
                                        grade?: number
                                        groups?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        illustration?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    pathId?: number
                                                    parent?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    children?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    files?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          alternativeText?: string
                                                          caption?: string
                                                          width?: number
                                                          height?: number
                                                          formats?: any
                                                          hash?: string
                                                          ext?: string
                                                          mime?: string
                                                          /** @format float */
                                                          size?: number
                                                          url?: string
                                                          previewUrl?: string
                                                          provider?: string
                                                          provider_metadata?: any
                                                          related?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          folder?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          folderPath?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                firstname?: string
                                                                lastname?: string
                                                                username?: string
                                                                /** @format email */
                                                                email?: string
                                                                resetPasswordToken?: string
                                                                registrationToken?: string
                                                                isActive?: boolean
                                                                roles?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      name?: string
                                                                      code?: string
                                                                      description?: string
                                                                      users?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }[]
                                                                      }
                                                                      permissions?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: {
                                                                            action?: string
                                                                            subject?: string
                                                                            properties?: any
                                                                            conditions?: any
                                                                            role?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            /** @format date-time */
                                                                            createdAt?: string
                                                                            /** @format date-time */
                                                                            updatedAt?: string
                                                                            createdBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            updatedBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                          }
                                                                        }[]
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                blocked?: boolean
                                                                preferedLanguage?: string
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    path?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        owner?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        tags?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                              label?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              localizations?: {
                                                data?: any[]
                                              }
                                              locale?: string
                                            }
                                          }[]
                                        }
                                        comments?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "summary" | "discussion" | "question"
                                              content?: string
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              author?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              has_spoil?: boolean
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        groupGrades?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              users_rating?: any
                                              /** @format float */
                                              average_rate?: number
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              slug?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  activities?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?:
                                          | "GROUP_RECOMMENDATION"
                                          | "GROUP_REVIEW"
                                          | "GROUP_SUMMARY"
                                          | "GROUP_MESSAGE"
                                          | "GROUP_REVIEW_MESSAGE"
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  type?: "half-private" | "private"
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            users_permissions_user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            invited_email?: string
                            invitation?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  membership?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  expired_at?: string
                                  /** @format date-time */
                                  validated_at?: string
                                  uid?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    users?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsRoleListResponseDataItemLocalized {
  id?: number
  attributes?: {
    name?: string
    description?: string
    type?: string
    permissions?: {
      data?: {
        id?: number
        attributes?: {
          action?: string
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership_role?: "admin" | "user"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  memberships?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  reviews?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        book?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              title?: string
                                              editor?: string
                                              format?: string
                                              isbn?: string
                                              pages?: number
                                              year?: number
                                              author?: string
                                              custom_isbn?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        summary?: string
                                        has_spoil?: boolean
                                        grade?: number
                                        groups?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        illustration?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    pathId?: number
                                                    parent?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    children?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    files?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          alternativeText?: string
                                                          caption?: string
                                                          width?: number
                                                          height?: number
                                                          formats?: any
                                                          hash?: string
                                                          ext?: string
                                                          mime?: string
                                                          /** @format float */
                                                          size?: number
                                                          url?: string
                                                          previewUrl?: string
                                                          provider?: string
                                                          provider_metadata?: any
                                                          related?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          folder?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          folderPath?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                firstname?: string
                                                                lastname?: string
                                                                username?: string
                                                                /** @format email */
                                                                email?: string
                                                                resetPasswordToken?: string
                                                                registrationToken?: string
                                                                isActive?: boolean
                                                                roles?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      name?: string
                                                                      code?: string
                                                                      description?: string
                                                                      users?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }[]
                                                                      }
                                                                      permissions?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: {
                                                                            action?: string
                                                                            subject?: string
                                                                            properties?: any
                                                                            conditions?: any
                                                                            role?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            /** @format date-time */
                                                                            createdAt?: string
                                                                            /** @format date-time */
                                                                            updatedAt?: string
                                                                            createdBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            updatedBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                          }
                                                                        }[]
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                blocked?: boolean
                                                                preferedLanguage?: string
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    path?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        owner?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        tags?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                              label?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              localizations?: {
                                                data?: any[]
                                              }
                                              locale?: string
                                            }
                                          }[]
                                        }
                                        comments?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "summary" | "discussion" | "question"
                                              content?: string
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              author?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              has_spoil?: boolean
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        groupGrades?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              users_rating?: any
                                              /** @format float */
                                              average_rate?: number
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              slug?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  activities?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?:
                                          | "GROUP_RECOMMENDATION"
                                          | "GROUP_REVIEW"
                                          | "GROUP_SUMMARY"
                                          | "GROUP_MESSAGE"
                                          | "GROUP_REVIEW_MESSAGE"
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  type?: "half-private" | "private"
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            users_permissions_user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            invited_email?: string
                            invitation?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  membership?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  expired_at?: string
                                  /** @format date-time */
                                  validated_at?: string
                                  uid?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    users?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsRoleListResponse {
  data?: UsersPermissionsRoleListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface UsersPermissionsRoleResponseDataObject {
  id?: number
  attributes?: {
    name?: string
    description?: string
    type?: string
    permissions?: {
      data?: {
        id?: number
        attributes?: {
          action?: string
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership_role?: "admin" | "user"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  memberships?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  reviews?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        book?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              title?: string
                                              editor?: string
                                              format?: string
                                              isbn?: string
                                              pages?: number
                                              year?: number
                                              author?: string
                                              custom_isbn?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        summary?: string
                                        has_spoil?: boolean
                                        grade?: number
                                        groups?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        illustration?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    pathId?: number
                                                    parent?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    children?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    files?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          alternativeText?: string
                                                          caption?: string
                                                          width?: number
                                                          height?: number
                                                          formats?: any
                                                          hash?: string
                                                          ext?: string
                                                          mime?: string
                                                          /** @format float */
                                                          size?: number
                                                          url?: string
                                                          previewUrl?: string
                                                          provider?: string
                                                          provider_metadata?: any
                                                          related?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          folder?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          folderPath?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                firstname?: string
                                                                lastname?: string
                                                                username?: string
                                                                /** @format email */
                                                                email?: string
                                                                resetPasswordToken?: string
                                                                registrationToken?: string
                                                                isActive?: boolean
                                                                roles?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      name?: string
                                                                      code?: string
                                                                      description?: string
                                                                      users?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }[]
                                                                      }
                                                                      permissions?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: {
                                                                            action?: string
                                                                            subject?: string
                                                                            properties?: any
                                                                            conditions?: any
                                                                            role?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            /** @format date-time */
                                                                            createdAt?: string
                                                                            /** @format date-time */
                                                                            updatedAt?: string
                                                                            createdBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            updatedBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                          }
                                                                        }[]
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                blocked?: boolean
                                                                preferedLanguage?: string
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    path?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        owner?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        tags?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                              label?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              localizations?: {
                                                data?: any[]
                                              }
                                              locale?: string
                                            }
                                          }[]
                                        }
                                        comments?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "summary" | "discussion" | "question"
                                              content?: string
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              author?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              has_spoil?: boolean
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        groupGrades?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              users_rating?: any
                                              /** @format float */
                                              average_rate?: number
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              slug?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  activities?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?:
                                          | "GROUP_RECOMMENDATION"
                                          | "GROUP_REVIEW"
                                          | "GROUP_SUMMARY"
                                          | "GROUP_MESSAGE"
                                          | "GROUP_REVIEW_MESSAGE"
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  type?: "half-private" | "private"
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            users_permissions_user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            invited_email?: string
                            invitation?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  membership?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  expired_at?: string
                                  /** @format date-time */
                                  validated_at?: string
                                  uid?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    users?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsRoleResponseDataObjectLocalized {
  id?: number
  attributes?: {
    name?: string
    description?: string
    type?: string
    permissions?: {
      data?: {
        id?: number
        attributes?: {
          action?: string
          role?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                description?: string
                type?: string
                permissions?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                users?: {
                  data?: {
                    id?: number
                    attributes?: {
                      username?: string
                      /** @format email */
                      email?: string
                      provider?: string
                      resetPasswordToken?: string
                      confirmationToken?: string
                      confirmed?: boolean
                      blocked?: boolean
                      role?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      cgu_consent?: boolean
                      newsletter_consent?: boolean
                      color?: string
                      first_name?: string
                      last_name?: string
                      memberships?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership_role?: "admin" | "user"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  description?: string
                                  memberships?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  reviews?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        book?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              title?: string
                                              editor?: string
                                              format?: string
                                              isbn?: string
                                              pages?: number
                                              year?: number
                                              author?: string
                                              custom_isbn?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    firstname?: string
                                                    lastname?: string
                                                    username?: string
                                                    /** @format email */
                                                    email?: string
                                                    resetPasswordToken?: string
                                                    registrationToken?: string
                                                    isActive?: boolean
                                                    roles?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          code?: string
                                                          description?: string
                                                          users?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          permissions?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                action?: string
                                                                subject?: string
                                                                properties?: any
                                                                conditions?: any
                                                                role?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    blocked?: boolean
                                                    preferedLanguage?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        summary?: string
                                        has_spoil?: boolean
                                        grade?: number
                                        groups?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        illustration?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              alternativeText?: string
                                              caption?: string
                                              width?: number
                                              height?: number
                                              formats?: any
                                              hash?: string
                                              ext?: string
                                              mime?: string
                                              /** @format float */
                                              size?: number
                                              url?: string
                                              previewUrl?: string
                                              provider?: string
                                              provider_metadata?: any
                                              related?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              folder?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    pathId?: number
                                                    parent?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    children?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    files?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          name?: string
                                                          alternativeText?: string
                                                          caption?: string
                                                          width?: number
                                                          height?: number
                                                          formats?: any
                                                          hash?: string
                                                          ext?: string
                                                          mime?: string
                                                          /** @format float */
                                                          size?: number
                                                          url?: string
                                                          previewUrl?: string
                                                          provider?: string
                                                          provider_metadata?: any
                                                          related?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }[]
                                                          }
                                                          folder?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          folderPath?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                firstname?: string
                                                                lastname?: string
                                                                username?: string
                                                                /** @format email */
                                                                email?: string
                                                                resetPasswordToken?: string
                                                                registrationToken?: string
                                                                isActive?: boolean
                                                                roles?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      name?: string
                                                                      code?: string
                                                                      description?: string
                                                                      users?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }[]
                                                                      }
                                                                      permissions?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: {
                                                                            action?: string
                                                                            subject?: string
                                                                            properties?: any
                                                                            conditions?: any
                                                                            role?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            /** @format date-time */
                                                                            createdAt?: string
                                                                            /** @format date-time */
                                                                            updatedAt?: string
                                                                            createdBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                            updatedBy?: {
                                                                              data?: {
                                                                                id?: number
                                                                                attributes?: object
                                                                              }
                                                                            }
                                                                          }
                                                                        }[]
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                blocked?: boolean
                                                                preferedLanguage?: string
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    path?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              folderPath?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        owner?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        tags?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                              label?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              localizations?: {
                                                data?: any[]
                                              }
                                              locale?: string
                                            }
                                          }[]
                                        }
                                        comments?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              type?: "summary" | "discussion" | "question"
                                              content?: string
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              author?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              has_spoil?: boolean
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        groupGrades?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              users_rating?: any
                                              /** @format float */
                                              average_rate?: number
                                              group?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              review?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              slug?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  activities?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?:
                                          | "GROUP_RECOMMENDATION"
                                          | "GROUP_REVIEW"
                                          | "GROUP_SUMMARY"
                                          | "GROUP_MESSAGE"
                                          | "GROUP_REVIEW_MESSAGE"
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  type?: "half-private" | "private"
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            users_permissions_user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            invited_email?: string
                            invitation?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  membership?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  expired_at?: string
                                  /** @format date-time */
                                  validated_at?: string
                                  uid?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      picture?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            alternativeText?: string
                            caption?: string
                            width?: number
                            height?: number
                            formats?: any
                            hash?: string
                            ext?: string
                            mime?: string
                            /** @format float */
                            size?: number
                            url?: string
                            previewUrl?: string
                            provider?: string
                            provider_metadata?: any
                            related?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            folder?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  pathId?: number
                                  parent?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  children?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  files?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              firstname?: string
                                              lastname?: string
                                              username?: string
                                              /** @format email */
                                              email?: string
                                              resetPasswordToken?: string
                                              registrationToken?: string
                                              isActive?: boolean
                                              roles?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    code?: string
                                                    description?: string
                                                    users?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    permissions?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          action?: string
                                                          subject?: string
                                                          properties?: any
                                                          conditions?: any
                                                          role?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }[]
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              blocked?: boolean
                                              preferedLanguage?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  path?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }
                            }
                            folderPath?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      notifications_consent?: boolean
                      comments?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      activities?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      section_visits?: {
                        data?: {
                          id?: number
                          attributes?: {
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                            group?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            last_seen?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      mobile_notifications_consent?: boolean
                      devices?: {
                        data?: {
                          id?: number
                          attributes?: {
                            token?: string
                            uuid?: string
                            user?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }[]
    }
    users?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsRoleResponse {
  data?: UsersPermissionsRoleResponseDataObject
  meta?: object
}

export interface UsersPermissionsUserRequest {
  data: {
    username: string
    /** @format email */
    email: string
    provider?: string
    /**
     * @format password
     * @example "*******"
     */
    password?: string
    resetPasswordToken?: string
    confirmationToken?: string
    confirmed?: boolean
    blocked?: boolean
    /** @example "string or id" */
    role?: number | string
    cgu_consent?: boolean
    newsletter_consent?: boolean
    color?: string
    first_name: string
    last_name: string
    memberships?: (number | string)[]
    /** @example "string or id" */
    picture?: number | string
    notifications_consent?: boolean
    comments?: (number | string)[]
    activities?: (number | string)[]
    section_visits?: (number | string)[]
    mobile_notifications_consent?: boolean
    devices?: (number | string)[]
  }
}

export interface UsersPermissionsUserListResponseDataItem {
  id?: number
  attributes?: {
    username?: string
    /** @format email */
    email?: string
    provider?: string
    resetPasswordToken?: string
    confirmationToken?: string
    confirmed?: boolean
    blocked?: boolean
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    cgu_consent?: boolean
    newsletter_consent?: boolean
    color?: string
    first_name?: string
    last_name?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    picture?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    notifications_consent?: boolean
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    section_visits?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    mobile_notifications_consent?: boolean
    devices?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsUserListResponseDataItemLocalized {
  id?: number
  attributes?: {
    username?: string
    /** @format email */
    email?: string
    provider?: string
    resetPasswordToken?: string
    confirmationToken?: string
    confirmed?: boolean
    blocked?: boolean
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    cgu_consent?: boolean
    newsletter_consent?: boolean
    color?: string
    first_name?: string
    last_name?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    picture?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    notifications_consent?: boolean
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    section_visits?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    mobile_notifications_consent?: boolean
    devices?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsUserListResponse {
  data?: UsersPermissionsUserListResponseDataItem[]
  meta?: {
    pagination?: {
      page?: number
      /** @min 25 */
      pageSize?: number
      /** @max 1 */
      pageCount?: number
      total?: number
    }
  }
}

export interface UsersPermissionsUserResponseDataObject {
  id?: number
  attributes?: {
    username?: string
    /** @format email */
    email?: string
    provider?: string
    resetPasswordToken?: string
    confirmationToken?: string
    confirmed?: boolean
    blocked?: boolean
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    cgu_consent?: boolean
    newsletter_consent?: boolean
    color?: string
    first_name?: string
    last_name?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    picture?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    notifications_consent?: boolean
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    section_visits?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    mobile_notifications_consent?: boolean
    devices?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsUserResponseDataObjectLocalized {
  id?: number
  attributes?: {
    username?: string
    /** @format email */
    email?: string
    provider?: string
    resetPasswordToken?: string
    confirmationToken?: string
    confirmed?: boolean
    blocked?: boolean
    role?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          description?: string
          type?: string
          permissions?: {
            data?: {
              id?: number
              attributes?: {
                action?: string
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: {
                      firstname?: string
                      lastname?: string
                      username?: string
                      /** @format email */
                      email?: string
                      resetPasswordToken?: string
                      registrationToken?: string
                      isActive?: boolean
                      roles?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            code?: string
                            description?: string
                            users?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            permissions?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  action?: string
                                  subject?: string
                                  properties?: any
                                  conditions?: any
                                  role?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }[]
                      }
                      blocked?: boolean
                      preferedLanguage?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          users?: {
            data?: {
              id?: number
              attributes?: {
                username?: string
                /** @format email */
                email?: string
                provider?: string
                resetPasswordToken?: string
                confirmationToken?: string
                confirmed?: boolean
                blocked?: boolean
                role?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                cgu_consent?: boolean
                newsletter_consent?: boolean
                color?: string
                first_name?: string
                last_name?: string
                memberships?: {
                  data?: {
                    id?: number
                    attributes?: {
                      membership_role?: "admin" | "user"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            description?: string
                            memberships?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            reviews?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  book?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        title?: string
                                        editor?: string
                                        format?: string
                                        isbn?: string
                                        pages?: number
                                        year?: number
                                        author?: string
                                        custom_isbn?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  summary?: string
                                  has_spoil?: boolean
                                  grade?: number
                                  groups?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  illustration?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        name?: string
                                        alternativeText?: string
                                        caption?: string
                                        width?: number
                                        height?: number
                                        formats?: any
                                        hash?: string
                                        ext?: string
                                        mime?: string
                                        /** @format float */
                                        size?: number
                                        url?: string
                                        previewUrl?: string
                                        provider?: string
                                        provider_metadata?: any
                                        related?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }[]
                                        }
                                        folder?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              pathId?: number
                                              parent?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              children?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              files?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    name?: string
                                                    alternativeText?: string
                                                    caption?: string
                                                    width?: number
                                                    height?: number
                                                    formats?: any
                                                    hash?: string
                                                    ext?: string
                                                    mime?: string
                                                    /** @format float */
                                                    size?: number
                                                    url?: string
                                                    previewUrl?: string
                                                    provider?: string
                                                    provider_metadata?: any
                                                    related?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }[]
                                                    }
                                                    folder?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    folderPath?: string
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: {
                                                          firstname?: string
                                                          lastname?: string
                                                          username?: string
                                                          /** @format email */
                                                          email?: string
                                                          resetPasswordToken?: string
                                                          registrationToken?: string
                                                          isActive?: boolean
                                                          roles?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: {
                                                                name?: string
                                                                code?: string
                                                                description?: string
                                                                users?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }[]
                                                                }
                                                                permissions?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: {
                                                                      action?: string
                                                                      subject?: string
                                                                      properties?: any
                                                                      conditions?: any
                                                                      role?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      /** @format date-time */
                                                                      createdAt?: string
                                                                      /** @format date-time */
                                                                      updatedAt?: string
                                                                      createdBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                      updatedBy?: {
                                                                        data?: {
                                                                          id?: number
                                                                          attributes?: object
                                                                        }
                                                                      }
                                                                    }
                                                                  }[]
                                                                }
                                                                /** @format date-time */
                                                                createdAt?: string
                                                                /** @format date-time */
                                                                updatedAt?: string
                                                                createdBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                                updatedBy?: {
                                                                  data?: {
                                                                    id?: number
                                                                    attributes?: object
                                                                  }
                                                                }
                                                              }
                                                            }[]
                                                          }
                                                          blocked?: boolean
                                                          preferedLanguage?: string
                                                          /** @format date-time */
                                                          createdAt?: string
                                                          /** @format date-time */
                                                          updatedAt?: string
                                                          createdBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                          updatedBy?: {
                                                            data?: {
                                                              id?: number
                                                              attributes?: object
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              path?: string
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }
                                        }
                                        folderPath?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  owner?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  tags?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "genre" | "humeur" | "style" | "lieu" | "epoque" | "prix"
                                        label?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        localizations?: {
                                          data?: any[]
                                        }
                                        locale?: string
                                      }
                                    }[]
                                  }
                                  comments?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        type?: "summary" | "discussion" | "question"
                                        content?: string
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        author?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        has_spoil?: boolean
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  groupGrades?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        users_rating?: any
                                        /** @format float */
                                        average_rate?: number
                                        group?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        review?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        slug?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            activities?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  type?:
                                    | "GROUP_RECOMMENDATION"
                                    | "GROUP_REVIEW"
                                    | "GROUP_SUMMARY"
                                    | "GROUP_MESSAGE"
                                    | "GROUP_REVIEW_MESSAGE"
                                  author?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  group?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  review?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            type?: "half-private" | "private"
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      users_permissions_user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      invited_email?: string
                      invitation?: {
                        data?: {
                          id?: number
                          attributes?: {
                            membership?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            /** @format date-time */
                            expired_at?: string
                            /** @format date-time */
                            validated_at?: string
                            uid?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                picture?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: {
                            name?: string
                            pathId?: number
                            parent?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            children?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }[]
                            }
                            files?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  alternativeText?: string
                                  caption?: string
                                  width?: number
                                  height?: number
                                  formats?: any
                                  hash?: string
                                  ext?: string
                                  mime?: string
                                  /** @format float */
                                  size?: number
                                  url?: string
                                  previewUrl?: string
                                  provider?: string
                                  provider_metadata?: any
                                  related?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  folder?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  folderPath?: string
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        firstname?: string
                                        lastname?: string
                                        username?: string
                                        /** @format email */
                                        email?: string
                                        resetPasswordToken?: string
                                        registrationToken?: string
                                        isActive?: boolean
                                        roles?: {
                                          data?: {
                                            id?: number
                                            attributes?: {
                                              name?: string
                                              code?: string
                                              description?: string
                                              users?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }[]
                                              }
                                              permissions?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: {
                                                    action?: string
                                                    subject?: string
                                                    properties?: any
                                                    conditions?: any
                                                    role?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    /** @format date-time */
                                                    createdAt?: string
                                                    /** @format date-time */
                                                    updatedAt?: string
                                                    createdBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                    updatedBy?: {
                                                      data?: {
                                                        id?: number
                                                        attributes?: object
                                                      }
                                                    }
                                                  }
                                                }[]
                                              }
                                              /** @format date-time */
                                              createdAt?: string
                                              /** @format date-time */
                                              updatedAt?: string
                                              createdBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                              updatedBy?: {
                                                data?: {
                                                  id?: number
                                                  attributes?: object
                                                }
                                              }
                                            }
                                          }[]
                                        }
                                        blocked?: boolean
                                        preferedLanguage?: string
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            path?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }
                }
                notifications_consent?: boolean
                comments?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                activities?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                section_visits?: {
                  data?: {
                    id?: number
                    attributes?: {
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      type?: "GROUP_REVIEW" | "GROUP_MESSAGE" | "GROUP_ACTIVITIES"
                      group?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      last_seen?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                mobile_notifications_consent?: boolean
                devices?: {
                  data?: {
                    id?: number
                    attributes?: {
                      token?: string
                      uuid?: string
                      user?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }[]
          }
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    cgu_consent?: boolean
    newsletter_consent?: boolean
    color?: string
    first_name?: string
    last_name?: string
    memberships?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    picture?: {
      data?: {
        id?: number
        attributes?: {
          name?: string
          alternativeText?: string
          caption?: string
          width?: number
          height?: number
          formats?: any
          hash?: string
          ext?: string
          mime?: string
          /** @format float */
          size?: number
          url?: string
          previewUrl?: string
          provider?: string
          provider_metadata?: any
          related?: {
            data?: {
              id?: number
              attributes?: object
            }[]
          }
          folder?: {
            data?: {
              id?: number
              attributes?: {
                name?: string
                pathId?: number
                parent?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                children?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }[]
                }
                files?: {
                  data?: {
                    id?: number
                    attributes?: {
                      name?: string
                      alternativeText?: string
                      caption?: string
                      width?: number
                      height?: number
                      formats?: any
                      hash?: string
                      ext?: string
                      mime?: string
                      /** @format float */
                      size?: number
                      url?: string
                      previewUrl?: string
                      provider?: string
                      provider_metadata?: any
                      related?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }[]
                      }
                      folder?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                      folderPath?: string
                      /** @format date-time */
                      createdAt?: string
                      /** @format date-time */
                      updatedAt?: string
                      createdBy?: {
                        data?: {
                          id?: number
                          attributes?: {
                            firstname?: string
                            lastname?: string
                            username?: string
                            /** @format email */
                            email?: string
                            resetPasswordToken?: string
                            registrationToken?: string
                            isActive?: boolean
                            roles?: {
                              data?: {
                                id?: number
                                attributes?: {
                                  name?: string
                                  code?: string
                                  description?: string
                                  users?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }[]
                                  }
                                  permissions?: {
                                    data?: {
                                      id?: number
                                      attributes?: {
                                        action?: string
                                        subject?: string
                                        properties?: any
                                        conditions?: any
                                        role?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        /** @format date-time */
                                        createdAt?: string
                                        /** @format date-time */
                                        updatedAt?: string
                                        createdBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                        updatedBy?: {
                                          data?: {
                                            id?: number
                                            attributes?: object
                                          }
                                        }
                                      }
                                    }[]
                                  }
                                  /** @format date-time */
                                  createdAt?: string
                                  /** @format date-time */
                                  updatedAt?: string
                                  createdBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                  updatedBy?: {
                                    data?: {
                                      id?: number
                                      attributes?: object
                                    }
                                  }
                                }
                              }[]
                            }
                            blocked?: boolean
                            preferedLanguage?: string
                            /** @format date-time */
                            createdAt?: string
                            /** @format date-time */
                            updatedAt?: string
                            createdBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                            updatedBy?: {
                              data?: {
                                id?: number
                                attributes?: object
                              }
                            }
                          }
                        }
                      }
                      updatedBy?: {
                        data?: {
                          id?: number
                          attributes?: object
                        }
                      }
                    }
                  }[]
                }
                path?: string
                /** @format date-time */
                createdAt?: string
                /** @format date-time */
                updatedAt?: string
                createdBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
                updatedBy?: {
                  data?: {
                    id?: number
                    attributes?: object
                  }
                }
              }
            }
          }
          folderPath?: string
          /** @format date-time */
          createdAt?: string
          /** @format date-time */
          updatedAt?: string
          createdBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
          updatedBy?: {
            data?: {
              id?: number
              attributes?: object
            }
          }
        }
      }
    }
    notifications_consent?: boolean
    comments?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    activities?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    section_visits?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    mobile_notifications_consent?: boolean
    devices?: {
      data?: {
        id?: number
        attributes?: object
      }[]
    }
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    updatedAt?: string
    createdBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
    updatedBy?: {
      data?: {
        id?: number
        attributes?: object
      }
    }
  }
}

export interface UsersPermissionsUserResponse {
  data?: UsersPermissionsUserResponseDataObject
  meta?: object
}

export interface UsersPermissionsRole {
  id?: number
  name?: string
  description?: string
  type?: string
  createdAt?: Date
  updatedAt?: Date
}

export interface UsersPermissionsUser {
  /** @example 1 */
  id?: number
  /** @example "foo.bar" */
  username?: string
  /** @example "foo.bar@strapi.io" */
  email?: string
  /** @example "local" */
  provider?: string
  /** @example true */
  confirmed?: boolean
  /** @example false */
  blocked?: boolean
  /** @example "2022-06-02T08:32:06.258Z" */
  createdAt?: Date
  /** @example "2022-06-02T08:32:06.267Z" */
  updatedAt?: Date
}

export interface UsersPermissionsUserRegistration {
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" */
  jwt?: string
  user?: UsersPermissionsUser
}

export type UsersPermissionsPermissionsTree = Record<
  string,
  {
    /** every controller of the api */
    controllers?: Record<
      string,
      Record<
        string,
        {
          enabled?: boolean
          policy?: string
        }
      >
    >
  }
>

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios"

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"]
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://localhost:1337" })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title DOCUMENTATION
 * @version 1.0.0
 * @license Apache 2.0 (https://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService YOUR_TERMS_OF_SERVICE_URL
 * @baseUrl http://localhost:1337
 * @externalDocs https://strapi.io/documentation/
 * @contact TEAM <contact-email@something.io> (mywebsite.io)
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  activities = {
    /**
     * No description
     *
     * @tags Activity
     * @name GetActivities
     * @request GET:/activities
     * @secure
     */
    getActivities: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ActivityListResponse, Error>({
        path: `/activities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  }
  articles = {
    /**
     * No description
     *
     * @tags Article
     * @name GetArticles
     * @request GET:/articles
     * @secure
     */
    getArticles: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ArticleListResponse, Error>({
        path: `/articles`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name PostArticles
     * @request POST:/articles
     * @secure
     */
    postArticles: (data: ArticleRequest, params: RequestParams = {}) =>
      this.request<ArticleResponse, Error>({
        path: `/articles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name GetArticlesId
     * @request GET:/articles/{id}
     * @secure
     */
    getArticlesId: (id: number, params: RequestParams = {}) =>
      this.request<ArticleResponse, Error>({
        path: `/articles/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name PutArticlesId
     * @request PUT:/articles/{id}
     * @secure
     */
    putArticlesId: (id: number, data: ArticleRequest, params: RequestParams = {}) =>
      this.request<ArticleResponse, Error>({
        path: `/articles/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name DeleteArticlesId
     * @request DELETE:/articles/{id}
     * @secure
     */
    deleteArticlesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/articles/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  books = {
    /**
     * No description
     *
     * @tags Book
     * @name GetBooks
     * @request GET:/books
     * @secure
     */
    getBooks: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<BookListResponse, Error>({
        path: `/books`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Book
     * @name PostBooks
     * @request POST:/books
     * @secure
     */
    postBooks: (data: BookRequest, params: RequestParams = {}) =>
      this.request<BookResponse, Error>({
        path: `/books`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Book
     * @name GetBooksId
     * @request GET:/books/{id}
     * @secure
     */
    getBooksId: (id: number, params: RequestParams = {}) =>
      this.request<BookResponse, Error>({
        path: `/books/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Book
     * @name PutBooksId
     * @request PUT:/books/{id}
     * @secure
     */
    putBooksId: (id: number, data: BookRequest, params: RequestParams = {}) =>
      this.request<BookResponse, Error>({
        path: `/books/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Book
     * @name DeleteBooksId
     * @request DELETE:/books/{id}
     * @secure
     */
    deleteBooksId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/books/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Book
     * @name GetBooksSearchIsbn
     * @request GET:/books/search/{isbn}
     * @secure
     */
    getBooksSearchIsbn: (isbn: number, params: RequestParams = {}) =>
      this.request<BookResponse, Error>({
        path: `/books/search/${isbn}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  cgu = {
    /**
     * No description
     *
     * @tags Cgu
     * @name GetCgu
     * @request GET:/cgu
     * @secure
     */
    getCgu: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CguListResponse, Error>({
        path: `/cgu`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cgu
     * @name PutCgu
     * @request PUT:/cgu
     * @secure
     */
    putCgu: (data: CguRequest, params: RequestParams = {}) =>
      this.request<CguResponse, Error>({
        path: `/cgu`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cgu
     * @name DeleteCgu
     * @request DELETE:/cgu
     * @secure
     */
    deleteCgu: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/cgu`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  comments = {
    /**
     * No description
     *
     * @tags Comment
     * @name GetComments
     * @request GET:/comments
     * @secure
     */
    getComments: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<CommentListResponse, Error>({
        path: `/comments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comment
     * @name PostComments
     * @request POST:/comments
     * @secure
     */
    postComments: (data: CommentRequest, params: RequestParams = {}) =>
      this.request<CommentResponse, Error>({
        path: `/comments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comment
     * @name GetCommentsId
     * @request GET:/comments/{id}
     * @secure
     */
    getCommentsId: (id: number, params: RequestParams = {}) =>
      this.request<CommentResponse, Error>({
        path: `/comments/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comment
     * @name PutCommentsId
     * @request PUT:/comments/{id}
     * @secure
     */
    putCommentsId: (id: number, data: CommentRequest, params: RequestParams = {}) =>
      this.request<CommentResponse, Error>({
        path: `/comments/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comment
     * @name DeleteCommentsId
     * @request DELETE:/comments/{id}
     * @secure
     */
    deleteCommentsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/comments/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  devices = {
    /**
     * No description
     *
     * @tags Device
     * @name GetDevices
     * @request GET:/devices
     * @secure
     */
    getDevices: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<DeviceListResponse, Error>({
        path: `/devices`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name PostDevices
     * @request POST:/devices
     * @secure
     */
    postDevices: (data: DeviceRequest, params: RequestParams = {}) =>
      this.request<DeviceResponse, Error>({
        path: `/devices`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name GetDevicesId
     * @request GET:/devices/{id}
     * @secure
     */
    getDevicesId: (id: number, params: RequestParams = {}) =>
      this.request<DeviceResponse, Error>({
        path: `/devices/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name PutDevicesId
     * @request PUT:/devices/{id}
     * @secure
     */
    putDevicesId: (id: number, data: DeviceRequest, params: RequestParams = {}) =>
      this.request<DeviceResponse, Error>({
        path: `/devices/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeleteDevicesId
     * @request DELETE:/devices/{id}
     * @secure
     */
    deleteDevicesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/devices/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  grades = {
    /**
     * No description
     *
     * @tags Grade
     * @name GetGrades
     * @request GET:/grades
     * @secure
     */
    getGrades: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<GradeListResponse, Error>({
        path: `/grades`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grade
     * @name PostGrades
     * @request POST:/grades
     * @secure
     */
    postGrades: (data: GradeRequest, params: RequestParams = {}) =>
      this.request<GradeResponse, Error>({
        path: `/grades`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grade
     * @name GetGradesId
     * @request GET:/grades/{id}
     * @secure
     */
    getGradesId: (id: number, params: RequestParams = {}) =>
      this.request<GradeResponse, Error>({
        path: `/grades/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grade
     * @name PutGradesId
     * @request PUT:/grades/{id}
     * @secure
     */
    putGradesId: (id: number, data: GradeRequest, params: RequestParams = {}) =>
      this.request<GradeResponse, Error>({
        path: `/grades/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grade
     * @name DeleteGradesId
     * @request DELETE:/grades/{id}
     * @secure
     */
    deleteGradesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/grades/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  groups = {
    /**
     * No description
     *
     * @tags Group
     * @name GetGroups
     * @request GET:/groups
     * @secure
     */
    getGroups: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<GroupListResponse, Error>({
        path: `/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group
     * @name PostGroups
     * @request POST:/groups
     * @secure
     */
    postGroups: (data: GroupRequest, params: RequestParams = {}) =>
      this.request<GroupResponse, Error>({
        path: `/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group
     * @name GetGroupsId
     * @request GET:/groups/{id}
     * @secure
     */
    getGroupsId: (id: number, params: RequestParams = {}) =>
      this.request<GroupResponse, Error>({
        path: `/groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group
     * @name PutGroupsId
     * @request PUT:/groups/{id}
     * @secure
     */
    putGroupsId: (id: number, data: GroupRequest, params: RequestParams = {}) =>
      this.request<GroupResponse, Error>({
        path: `/groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group
     * @name DeleteGroupsId
     * @request DELETE:/groups/{id}
     * @secure
     */
    deleteGroupsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/groups/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  invitations = {
    /**
     * No description
     *
     * @tags Invitation
     * @name GetInvitations
     * @request GET:/invitations
     * @secure
     */
    getInvitations: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvitationListResponse, Error>({
        path: `/invitations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitation
     * @name PostInvitations
     * @request POST:/invitations
     * @secure
     */
    postInvitations: (data: InvitationRequest, params: RequestParams = {}) =>
      this.request<InvitationResponse, Error>({
        path: `/invitations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitation
     * @name GetInvitationsId
     * @request GET:/invitations/{id}
     * @secure
     */
    getInvitationsId: (id: number, params: RequestParams = {}) =>
      this.request<InvitationResponse, Error>({
        path: `/invitations/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitation
     * @name PutInvitationsId
     * @request PUT:/invitations/{id}
     * @secure
     */
    putInvitationsId: (id: number, data: InvitationRequest, params: RequestParams = {}) =>
      this.request<InvitationResponse, Error>({
        path: `/invitations/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invitation
     * @name DeleteInvitationsId
     * @request DELETE:/invitations/{id}
     * @secure
     */
    deleteInvitationsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/invitations/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  landingPage = {
    /**
     * No description
     *
     * @tags Landing-page
     * @name GetLandingPage
     * @request GET:/landing-page
     * @secure
     */
    getLandingPage: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<LandingPageListResponse, Error>({
        path: `/landing-page`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Landing-page
     * @name PutLandingPage
     * @request PUT:/landing-page
     * @secure
     */
    putLandingPage: (data: LandingPageRequest, params: RequestParams = {}) =>
      this.request<LandingPageResponse, Error>({
        path: `/landing-page`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Landing-page
     * @name DeleteLandingPage
     * @request DELETE:/landing-page
     * @secure
     */
    deleteLandingPage: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/landing-page`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  legalNotice = {
    /**
     * No description
     *
     * @tags Legal-notice
     * @name GetLegalNotice
     * @request GET:/legal-notice
     * @secure
     */
    getLegalNotice: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<LegalNoticeListResponse, Error>({
        path: `/legal-notice`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Legal-notice
     * @name PutLegalNotice
     * @request PUT:/legal-notice
     * @secure
     */
    putLegalNotice: (data: LegalNoticeRequest, params: RequestParams = {}) =>
      this.request<LegalNoticeResponse, Error>({
        path: `/legal-notice`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Legal-notice
     * @name DeleteLegalNotice
     * @request DELETE:/legal-notice
     * @secure
     */
    deleteLegalNotice: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/legal-notice`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  memberships = {
    /**
     * No description
     *
     * @tags Membership
     * @name GetMemberships
     * @request GET:/memberships
     * @secure
     */
    getMemberships: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MembershipListResponse, Error>({
        path: `/memberships`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Membership
     * @name PostMemberships
     * @request POST:/memberships
     * @secure
     */
    postMemberships: (data: MembershipRequest, params: RequestParams = {}) =>
      this.request<MembershipResponse, Error>({
        path: `/memberships`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Membership
     * @name GetMembershipsId
     * @request GET:/memberships/{id}
     * @secure
     */
    getMembershipsId: (id: number, params: RequestParams = {}) =>
      this.request<MembershipResponse, Error>({
        path: `/memberships/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Membership
     * @name PutMembershipsId
     * @request PUT:/memberships/{id}
     * @secure
     */
    putMembershipsId: (id: number, data: MembershipRequest, params: RequestParams = {}) =>
      this.request<MembershipResponse, Error>({
        path: `/memberships/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Membership
     * @name DeleteMembershipsId
     * @request DELETE:/memberships/{id}
     * @secure
     */
    deleteMembershipsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/memberships/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  partnership = {
    /**
     * No description
     *
     * @tags Partnership
     * @name GetPartnership
     * @request GET:/partnership
     * @secure
     */
    getPartnership: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PartnershipListResponse, Error>({
        path: `/partnership`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Partnership
     * @name PutPartnership
     * @request PUT:/partnership
     * @secure
     */
    putPartnership: (data: PartnershipRequest, params: RequestParams = {}) =>
      this.request<PartnershipResponse, Error>({
        path: `/partnership`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Partnership
     * @name DeletePartnership
     * @request DELETE:/partnership
     * @secure
     */
    deletePartnership: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/partnership`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  privacyPolicy = {
    /**
     * No description
     *
     * @tags Privacy-policy
     * @name GetPrivacyPolicy
     * @request GET:/privacy-policy
     * @secure
     */
    getPrivacyPolicy: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PrivacyPolicyListResponse, Error>({
        path: `/privacy-policy`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Privacy-policy
     * @name PutPrivacyPolicy
     * @request PUT:/privacy-policy
     * @secure
     */
    putPrivacyPolicy: (data: PrivacyPolicyRequest, params: RequestParams = {}) =>
      this.request<PrivacyPolicyResponse, Error>({
        path: `/privacy-policy`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Privacy-policy
     * @name DeletePrivacyPolicy
     * @request DELETE:/privacy-policy
     * @secure
     */
    deletePrivacyPolicy: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/privacy-policy`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  recommendations = {
    /**
     * No description
     *
     * @tags Recommendation
     * @name GetRecommendations
     * @request GET:/recommendations
     * @secure
     */
    getRecommendations: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<RecommendationListResponse, Error>({
        path: `/recommendations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recommendation
     * @name PostRecommendations
     * @request POST:/recommendations
     * @secure
     */
    postRecommendations: (data: RecommendationRequest, params: RequestParams = {}) =>
      this.request<RecommendationResponse, Error>({
        path: `/recommendations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recommendation
     * @name GetRecommendationsId
     * @request GET:/recommendations/{id}
     * @secure
     */
    getRecommendationsId: (id: number, params: RequestParams = {}) =>
      this.request<RecommendationResponse, Error>({
        path: `/recommendations/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recommendation
     * @name PutRecommendationsId
     * @request PUT:/recommendations/{id}
     * @secure
     */
    putRecommendationsId: (id: number, data: RecommendationRequest, params: RequestParams = {}) =>
      this.request<RecommendationResponse, Error>({
        path: `/recommendations/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recommendation
     * @name DeleteRecommendationsId
     * @request DELETE:/recommendations/{id}
     * @secure
     */
    deleteRecommendationsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/recommendations/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  reviews = {
    /**
     * No description
     *
     * @tags Review
     * @name GetReviews
     * @request GET:/reviews
     * @secure
     */
    getReviews: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ReviewListResponse, Error>({
        path: `/reviews`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name PostReviews
     * @request POST:/reviews
     * @secure
     */
    postReviews: (data: ReviewRequest, params: RequestParams = {}) =>
      this.request<ReviewResponse, Error>({
        path: `/reviews`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name GetReviewsId
     * @request GET:/reviews/{id}
     * @secure
     */
    getReviewsId: (id: number, params: RequestParams = {}) =>
      this.request<ReviewResponse, Error>({
        path: `/reviews/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name PutReviewsId
     * @request PUT:/reviews/{id}
     * @secure
     */
    putReviewsId: (id: number, data: ReviewRequest, params: RequestParams = {}) =>
      this.request<ReviewResponse, Error>({
        path: `/reviews/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name DeleteReviewsId
     * @request DELETE:/reviews/{id}
     * @secure
     */
    deleteReviewsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/reviews/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  sectionVisits = {
    /**
     * No description
     *
     * @tags Section-visit
     * @name PostSectionVisits
     * @request POST:/section-visits
     * @secure
     */
    postSectionVisits: (data: SectionVisitRequest, params: RequestParams = {}) =>
      this.request<SectionVisitResponse, Error>({
        path: `/section-visits`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  tags = {
    /**
     * No description
     *
     * @tags Tag
     * @name GetTags
     * @request GET:/tags
     * @secure
     */
    getTags: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<TagListResponse, Error>({
        path: `/tags`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name PostTags
     * @request POST:/tags
     * @secure
     */
    postTags: (data: TagRequest, params: RequestParams = {}) =>
      this.request<TagResponse, Error>({
        path: `/tags`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name GetTagsId
     * @request GET:/tags/{id}
     * @secure
     */
    getTagsId: (id: number, params: RequestParams = {}) =>
      this.request<TagResponse, Error>({
        path: `/tags/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name PutTagsId
     * @request PUT:/tags/{id}
     * @secure
     */
    putTagsId: (id: number, data: TagRequest, params: RequestParams = {}) =>
      this.request<TagResponse, Error>({
        path: `/tags/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name DeleteTagsId
     * @request DELETE:/tags/{id}
     * @secure
     */
    deleteTagsId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/tags/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name PostTagsIdLocalizations
     * @request POST:/tags/{id}/localizations
     * @secure
     */
    postTagsIdLocalizations: (id: number, data: TagLocalizationRequest, params: RequestParams = {}) =>
      this.request<TagLocalizationResponse, Error>({
        path: `/tags/${id}/localizations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  tutorialNotice = {
    /**
     * No description
     *
     * @tags Tutorial-notice
     * @name GetTutorialNotice
     * @request GET:/tutorial-notice
     * @secure
     */
    getTutorialNotice: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<TutorialNoticeListResponse, Error>({
        path: `/tutorial-notice`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tutorial-notice
     * @name PutTutorialNotice
     * @request PUT:/tutorial-notice
     * @secure
     */
    putTutorialNotice: (data: TutorialNoticeRequest, params: RequestParams = {}) =>
      this.request<TutorialNoticeResponse, Error>({
        path: `/tutorial-notice`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tutorial-notice
     * @name DeleteTutorialNotice
     * @request DELETE:/tutorial-notice
     * @secure
     */
    deleteTutorialNotice: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/tutorial-notice`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  upload = {
    /**
     * No description
     *
     * @tags Upload - File
     * @name GetUploadFiles
     * @request GET:/upload/files
     * @secure
     */
    getUploadFiles: (
      query?: {
        /** Sort by attributes ascending (asc) or descending (desc) */
        sort?: string
        /** Return page/pageSize (default: true) */
        "pagination[withCount]"?: boolean
        /** Page number (default: 0) */
        "pagination[page]"?: number
        /** Page size (default: 25) */
        "pagination[pageSize]"?: number
        /** Offset value (default: 0) */
        "pagination[start]"?: number
        /** Number of entities to return (default: 25) */
        "pagination[limit]"?: number
        /** Fields to return (ex: title,author) */
        fields?: string
        /** Relations to return */
        populate?: string
        /** Filters to apply */
        filters?: object
        /** Locale to apply */
        locale?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UploadFileListResponse, Error>({
        path: `/upload/files`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload - File
     * @name GetUploadFilesId
     * @request GET:/upload/files/{id}
     * @secure
     */
    getUploadFilesId: (id: number, params: RequestParams = {}) =>
      this.request<UploadFileResponse, Error>({
        path: `/upload/files/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload - File
     * @name DeleteUploadFilesId
     * @request DELETE:/upload/files/{id}
     * @secure
     */
    deleteUploadFilesId: (id: number, params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/upload/files/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  usersPermissions = {
    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name PermissionsList
     * @summary Get default generated permissions
     * @request GET:/users-permissions/permissions
     * @secure
     */
    permissionsList: (params: RequestParams = {}) =>
      this.request<
        {
          permissions?: UsersPermissionsPermissionsTree
        },
        Error
      >({
        path: `/users-permissions/permissions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesDetail
     * @summary Get a role
     * @request GET:/users-permissions/roles/{id}
     * @secure
     */
    rolesDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          role?: UsersPermissionsRole
        },
        Error
      >({
        path: `/users-permissions/roles/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesList
     * @summary List roles
     * @request GET:/users-permissions/roles
     * @secure
     */
    rolesList: (params: RequestParams = {}) =>
      this.request<
        {
          roles?: (UsersPermissionsRole & {
            nb_users?: number
          })[]
        },
        Error
      >({
        path: `/users-permissions/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesCreate
     * @summary Create a role
     * @request POST:/users-permissions/roles
     * @secure
     */
    rolesCreate: (
      data: {
        name?: string
        description?: string
        type?: string
        permissions?: UsersPermissionsPermissionsTree
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          ok?: "true"
        },
        Error
      >({
        path: `/users-permissions/roles`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesUpdate
     * @summary Update a role
     * @request PUT:/users-permissions/roles/{role}
     * @secure
     */
    rolesUpdate: (
      role: string,
      data: {
        name?: string
        description?: string
        type?: string
        permissions?: UsersPermissionsPermissionsTree
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          ok?: "true"
        },
        Error
      >({
        path: `/users-permissions/roles/${role}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name RolesDelete
     * @summary Delete a role
     * @request DELETE:/users-permissions/roles/{role}
     * @secure
     */
    rolesDelete: (role: string, params: RequestParams = {}) =>
      this.request<
        {
          ok?: "true"
        },
        Error
      >({
        path: `/users-permissions/roles/${role}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name CountList
     * @summary Get user count
     * @request GET:/users/count
     * @secure
     */
    countList: (params: RequestParams = {}) =>
      this.request<number, Error>({
        path: `/users/count`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersList
     * @summary Get list of users
     * @request GET:/users
     * @secure
     */
    usersList: (params: RequestParams = {}) =>
      this.request<UsersPermissionsUser[], Error>({
        path: `/users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersCreate
     * @summary Create a user
     * @request POST:/users
     * @secure
     */
    usersCreate: (
      data: {
        email: string
        username: string
        password: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        UsersPermissionsUser & {
          role?: UsersPermissionsRole
        },
        Error
      >({
        path: `/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name GetUsers
     * @summary Get authenticated user info
     * @request GET:/users/me
     * @secure
     */
    getUsers: (params: RequestParams = {}) =>
      this.request<UsersPermissionsUser, Error>({
        path: `/users/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersDetail
     * @summary Get a user
     * @request GET:/users/{id}
     * @secure
     */
    usersDetail: (id: string, params: RequestParams = {}) =>
      this.request<UsersPermissionsUser, Error>({
        path: `/users/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersUpdate
     * @summary Update a user
     * @request PUT:/users/{id}
     * @secure
     */
    usersUpdate: (
      id: string,
      data: {
        email: string
        username: string
        password: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        UsersPermissionsUser & {
          role?: UsersPermissionsRole
        },
        Error
      >({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Users & Roles
     * @name UsersDelete
     * @summary Delete a user
     * @request DELETE:/users/{id}
     * @secure
     */
    usersDelete: (id: string, params: RequestParams = {}) =>
      this.request<UsersPermissionsUser, Error>({
        path: `/users/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  }
  user = {
    /**
     * No description
     *
     * @tags Users-permissions - User
     * @name PutUserMe
     * @request PUT:/user/me
     * @secure
     */
    putUserMe: (data: UsersPermissionsUserRequest, params: RequestParams = {}) =>
      this.request<UsersPermissionsUserResponse, Error>({
        path: `/user/me`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  connect = {
    /**
     * @description Redirects to provider login before being redirect to /auth/{provider}/callback
     *
     * @tags Users-Permissions - Auth
     * @name List
     * @summary Login with a provider
     * @request GET:/connect/(.*)
     * @secure
     */
    list: (params: RequestParams = {}) =>
      this.request<any, void | Error>({
        path: `/connect/(.*)`,
        method: "GET",
        secure: true,
        ...params,
      }),
  }
  auth = {
    /**
     * @description Returns a jwt token and user info
     *
     * @tags Users-Permissions - Auth
     * @name LocalCreate
     * @summary Local login
     * @request POST:/auth/local
     * @secure
     */
    localCreate: (
      data: {
        identifier?: string
        password?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/local`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a jwt token and user info
     *
     * @tags Users-Permissions - Auth
     * @name LocalRegisterCreate
     * @summary Register a user
     * @request POST:/auth/local/register
     * @secure
     */
    localRegisterCreate: (
      data: {
        username?: string
        email?: string
        password?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/local/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name CallbackDetail
     * @summary Default Callback from provider auth
     * @request GET:/auth/{provider}/callback
     * @secure
     */
    callbackDetail: (provider: string, params: RequestParams = {}) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/${provider}/callback`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name ForgotPasswordCreate
     * @summary Send rest password email
     * @request POST:/auth/forgot-password
     * @secure
     */
    forgotPasswordCreate: (
      data: {
        email?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          ok?: "true"
        },
        Error
      >({
        path: `/auth/forgot-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name ResetPasswordCreate
     * @summary Rest user password
     * @request POST:/auth/reset-password
     * @secure
     */
    resetPasswordCreate: (
      data: {
        password?: string
        passwordConfirmation?: string
        code?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name ChangePasswordCreate
     * @summary Update user's own password
     * @request POST:/auth/change-password
     * @secure
     */
    changePasswordCreate: (
      data: {
        password: string
        currentPassword: string
        passwordConfirmation: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersPermissionsUserRegistration, Error>({
        path: `/auth/change-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name EmailConfirmationList
     * @summary Confirm user email
     * @request GET:/auth/email-confirmation
     * @secure
     */
    emailConfirmationList: (
      query?: {
        /** confirmation token received by email */
        confirmation?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void | Error>({
        path: `/auth/email-confirmation`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users-Permissions - Auth
     * @name SendEmailConfirmationCreate
     * @summary Send confirmation email
     * @request POST:/auth/send-email-confirmation
     * @secure
     */
    sendEmailConfirmationCreate: (
      data: {
        email?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          email?: string
          sent?: "true"
        },
        Error
      >({
        path: `/auth/send-email-confirmation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
}
