import { desktopThreshold } from "@/utils/theme"
import { HStack, useMediaQuery, Text } from "@chakra-ui/react"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Logo from "../Logo"
import AuthActions from "./AuthActions"
import BurgerMenu from "./BurgerMenu"

const ToolBar = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isDesktop] = useMediaQuery(`(min-width: ${desktopThreshold})`)
  const { status } = useSession()
  const isLoggedIn = status === "authenticated"

  const isLandingPage = router.pathname === "/" && !isLoggedIn

  return (
    <HStack
      padding={4}
      justifyContent="space-between"
      bg="primaryColor"
      height="60px"
      fontFamily="GTMaru"
    >
      <Logo width="120px" />
      {isDesktop && isLandingPage && (
        <HStack spacing={10}>
          <AuthActions />
        </HStack>
      )}
      {isLoggedIn && isDesktop && (
        <Text fontFamily="GTMaru">{t("toolbar.catchphrase")}</Text>
      )}

      <BurgerMenu />
    </HStack>
  )
}

export default ToolBar
