"use client"
import { extendTheme, theme } from "@chakra-ui/react"
import { fonts } from "../assets/fonts"
import { avatarTheme } from "./customComponents/avatarTheme"
import buttonTheme from "./customComponents/buttonTheme"
import iconButtonTheme from "./customComponents/iconButtonTheme"

export const desktopThreshold = "768px"

const colors = {
  primaryColor: "#EFFF00",
  secondary: {
    light: "#EAEAEA",
    medium: "#D9D9D9",
    dark: "#969696",
  },
  gray: {
    50: "#D9D9D94D",
    100: "#A9A9A9",
  },
}

const sizes = {
  ...theme.space,
  container: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1400px",
  },
}

const customTheme = extendTheme({
  sizes,
  colors,
  components: {
    Button: buttonTheme,
    IconButton: iconButtonTheme,
    Avatar: avatarTheme,
  },
  fonts,
})

export default customTheme
